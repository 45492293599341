.wrapper {
    padding-top: 10px;
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 40px;
    max-width: 1000px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
}

.image {
    width: 50%;
    height: 65vh;
    object-fit: cover;
    padding-bottom: 50px;
}

.infoContainer {
    flex: 1;
    padding-top: 0px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-left: 70px;
}


@media (max-width: 850px) {
    .wrapper {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        max-width: 1000px;
        width: 92%;
        flex-wrap: wrap;
        display: flex;
    }

    .image {
        width: 100%;
        height: 45vh;
        max-width: 800px;
        object-fit: cover;
    }

    .infoContainer {
        padding-top: 0px;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-left: 0px;
    }
    
}