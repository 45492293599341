.container {
    height: 75vh;
    width: 100%;
    margin: 0px auto;
    position: relative;
}

.info {
    position: absolute;
    border: #007746 2px solid;
    height: 160px;
    width: 400px;
    margin-top: -490px;
    margin-left: 50px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 0px;
    padding-bottom: 120px;
    background: #f9f9f9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.link {
    color: #000 !important;
}

.title {
    color: #000 !important;
    margin-right: 14px; 
    font-weight: 700;
    word-spacing: 1.5px;  
    font-size: 40px;
}

.title2 {
    color: #000 !important;
    margin-right: 14px; 
    padding-top: 10px;
    font-weight: 300;
    word-spacing: 1.5px;  
    font-size: 25px;
}

.title3 {
    color: #000 !important;
    margin-right: 14px; 
    padding-top: 15px;
    font-weight: 300;
    word-spacing: 1.5px;  
    font-size: 22px;
}

.closed {
    color: #FF0000 !important;
    margin-right: 14px; 
    padding-top: 15px;
    font-weight: 300;
    word-spacing: 1.5px;  
    font-size: 22px;
}


@media (max-width: 700px) { 

    .info {
        border: none;
        top: 0;
        margin: 0px;
        width: 90VW;
        display: block;
        padding-top: 30px;
        padding-left: 25px;
        padding-right: 30px;
        padding-bottom: 120px;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
    }
}


@media (max-width: 500px) { 


    .info {
        top: 0;
        left: 0;
        right: 0;
        margin: 0px;
        width: 86%;
        padding-bottom: 140px;
        padding-left: 25px;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
    }

}
