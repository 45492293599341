.container {
    padding-top: 80px;
    display: flex;
    margin-top: 0px;
    background-color: #ededed;
    border-top: 2px solid #000;
}

.left {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 90px;
    padding-bottom: 200px;
}

@media (max-width: 850px) {
    .left {
        padding-left: 30px;
    }
}

