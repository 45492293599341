.container {
  padding-top: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-left: 90px;
}

.infoContainer {
  flex: 1;
  width: 50%;
  height: 50%;
  padding-top: 20px;
}

.desc {
    padding-bottom: 12px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1.6;
}

@media (max-width: 850px) {
    .container {
        padding-top: 30px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }

    .infoContainer {
        width: 90%;
        height: 10%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 10px;
    }
}