a {
  text-decoration: none;
  color: #101010;
  text-underline-position: below;
}

.Navbar {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
  }
  
  .Navbar > .nav-logo {
    font-weight: 700;
    font-size: 21px;
    color: #101010;
    margin-top: 12px;
    margin-left: 84px;
  }
  
  .Navbar > .nav-items > a {
    color: #101010;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-decoration: none;
    position: relative;
    opacity: 1;
    margin-right: 80px;
    margin-left: -56px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .Navbar > .nav-items > a:hover {
    opacity: 0.8;
  }
  
  .Navbar > .nav-items > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #f9f9f9;
    transition: all 0.45s;
  }
  
  .Navbar > .nav-items > a:hover::before {
    width: 100%;
  }
  
  .Navbar > .nav-toggle {
    display: none;
  }
  
  @media (max-width: 850px) {
    .Navbar > .nav-items {
      position: absolute;
      top: 130px;
      display: flex;
      flex-direction: column;
      background: #f9f9f9;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      transition: all .45s;
      z-index: 10;
    }

    .Navbar > .nav-items > a {
      color: #101010;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
      text-decoration: none;
      position: relative;
      opacity: 0.9;
      margin-right: 20px;
      margin-left: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .Navbar > .nav-logo {
      font-weight: 700;
      font-size: 21px;
      margin: 15px;
      color: #101010;
      margin-left: 20px;
    }
  
    .Navbar > .nav-items > a::before {
        background: transparent;
    }
  
    .Navbar > .nav-items.open {
        transform: translateX(0);
    }
  
    .Navbar > .nav-toggle {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 16px;
    }
  
    .nav-toggle > .bar {
      position: relative;
      width: 32px;
      height: 2px;
      background: #101010;
      transition: all 0.45s ease-in-out;
    }
  
    .nav-toggle > .bar::before,
    .nav-toggle > .bar::after {
      content: "";
      position: absolute;
      height: 2px;
      background: #101010;
      border-radius: 2px;
      transition: all 0.45s ease-in-out;
    }
  
    .nav-toggle > .bar::before {
      width: 25px;
      transform: translateY(-8px);
      right: 0;
    }
  
    .nav-toggle > .bar::after {
      width: 32px;
      transform: translateY(8px);
    }
  
    .nav-toggle.open > .bar {
        transform: translateX(-40px);
        background: transparent;
        z-index: 100;
    }
  
    .nav-toggle.open > .bar::before {
        width: 32px;
        transform: rotate(45deg) translate(26px, -26px);
        
    }
  
    .nav-toggle.open > .bar::after {
      transform: rotate(-45deg) translate(26px, 26px);
    }
  
  }