.link {
    color: #000 !important;
    text-decoration: underline;
}

.container {
    margin-top: -20px !important;
    padding-top: 120px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-left: 90px;
    /* background-color: #F8F8F9; */
    background-color: #f9f9f9;
}

.container2 {
    padding-top: 120px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-left: 90px;
    background-color: #f9f9f9;
    border-top: 2px solid #000;
}

.imageContainer {
    height: 70%;
    flex: 1;
    margin-top: -100px;
    padding-left: 10px;
    padding-right: 50px;
}

.infoContainer {
    flex: 1;
    width: 50%;
    height: 50%;
    padding-top: 20px;
}

.hr {
    width: 100% !important;
}

@media (max-width: 850px) {
    .container {
        overflow-x: hidden !important;
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }

    .container2 {
        overflow-x: hidden !important;
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }

    .imageContainer {
        display: none;
    }
    .infoContainer {
        width: 90%;
        height: 10%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
    }

    .popup {
        width: 200%;
    }
}
